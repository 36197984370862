
import * as animationData from '~/assets/lottie/loader.json'

export default {
  name: 'AppLayout',
  computed: {
    lottieData() {
      return animationData.default
    }
  },
}
