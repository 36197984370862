
import { mapState } from "vuex";

import handoffMixin from '../../mixins/handoff'

export default {
  mixins: [ handoffMixin ],
  computed: {
    ...mapState(["location"]),
    ...mapState({
      showLocationDeliveryForm: state => state.app.showLocationDeliveryForm,
    })
  },
};
