
import DateSelect from "./DateSelect.vue";
// import LocSelector from './LocSelector.vue'
import Checkbox from "./Checkbox.vue";
import InputDefault from "./InputDefault.vue";

export default {
  name: "FormField",
  components: {
    DateSelect,
    // LocSelector,
    Checkbox,
    InputDefault,
  },
  props: {
    columns: {
      type: Number,
      default: 1,
    },
    field: {
      type: Object,
      default: {
        name: "",
        prefix: "",
        label: "",
        type: "text",
        value: "",
        valid: /.*?/,
        touched: false,
        error: null,
        errors: {
          invalid: "Please enter a valid value.",
        },
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    toLink() {
      if (typeof window === "undefined") {
        return this.field.to;
      } else {
        return `${this.field.to}${window.location.search}`;
      }
    },
  },
};
