
  import { mapState, mapActions } from 'vuex'

  import mainMenu from '~/assets/menus/main.json'

  export default {
    data() {
      return {
        closed: [],
        eclubEmail: ''
      }
    },
    methods: {
      ...mapActions(["sendRequest"]),
      toggleLinks(col) {
        if (!this.closed.includes(col)) {
          this.closed.push(col)
        } else {
          this.closed.splice(this.closed.indexOf(col), 1)
        }
      },
      async subscribeToEclub() {
        const config = {
          method: "post",
          url: "eclub",
          data: {
            email: this.eclubEmail
          }
        };

        this.sendRequest(config);

        if (!this.user) {
          this.$router.push({
            path: '/account/signup',
            query: {
              email: this.eclubEmail
            }
          })
        } else {
          this.$router.push('/account')
        }
      }
    },
    computed: {
      ...mapState([ 'appInfo', 'user', 'cart', 'location' ]),
      mainMenu() {
        return mainMenu.columns.map(col => {
          return {
            ...col,
            links: col.links.filter(link => !link.hideOnFooter)
          }
        })
      },
      appVersion() {
        const appVersion = process.env.APP_VERSION || ''
        const libraryVersion = process.env.LIBRARY_VERSION || ''
        console.info('App version:', `v${appVersion}`)
        console.info('Library version:', `v${libraryVersion}`)
        let text = '| '
        text += appVersion ? `v${appVersion}` : ''
        if (this.cart) {
          text+= ' | Basket: '+ this.cart.id
        }
        return text
      },
      careersLink() {
        const jobLink = this.location?.c_joblink;

        if (!jobLink) {
          return {
            link: "/careers",
            target: "_self",
            type: "relative",
          };
        }

        const isRelative = jobLink.includes("https://mellowmushroom.com/");

        if (isRelative) {
          return {
            link: jobLink
              .replace(/https:\/\/mellowmushroom\.com\//g, "/")
              .replace("/mellow-jobs/apply/", "/careers"),
            target: "_self",
            type: "relative",
          };
        }

        return {
          link: this.location.c_joblink,
          target: "_blank",
          type: "absolute",
        };
      },
    }
  }
