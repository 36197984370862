
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showAddedMessage: false,
    };
  },
  computed: {
    // ...mapState({
    //   product: state => state.product?.data,
    //   quantity: state => state.product.quantity,
    // }),
    ...mapState(["location", "cart"]),
    isDefaultMenu() {
      return this.location ? false : true;
    },
    images() {
      return this.product && this.product.images ? this.product.images : null;
    },
    imageUrl() {
      if (this.images) {
        if (this.images && this.images["marketplace-product"]) {
          return this.images["marketplace-product"].url;
        }
        if (this.images && this.images.default) {
          return this.images.default.url;
        }
      }
      return "";
    },
    correctedNutritionLifestyle() {
      // Handle typo, nutrition_lifestyle can come as 'nutrition_lifestyle ', with a trailing space
      return (
        this.product.nutrition_lifestyle || this.product["nutrition_lifestyle "]
      );
    },
    calories() {
      let base = this.product.nutrition?.calories?.base;
      let max = this.product.nutrition?.calories?.max;
      // if (base) {
      //   base = base.toString().replace("-", "");
      // }
      // if (max) {
      //   max = max.toString().replace("-", "");
      // }
      return (
        (this.product.cost && base !== null ? " | " : "") +
        (base !== null ? base : "") +
        (max !== null ? "-" + max : "") +
        (base !== null ? " Cal" : "")
      );
    },
    upsellInCart() {
      return this.cart.products.find(
        (product) => product.product_id === this.product.product_id
      );
    },
  },
  methods: {
    ...mapMutations(["setLoading", "setErrors", "setMenu", "setCart"]),
    ...mapMutations({
      setShowCart: "setShowCart",
      setLoading: "setLoading",
      setProduct: "product/setProduct",
    }),
    ...mapActions({
      sendRequest: "sendRequest",
      processMods: "product/processMods",
    }),
    onProductClick() {
      if (!this.product.isInCart) {
        this.$emit("quick-add", this.product);
      }
    },
    async decreaseQuantity() {
      if (!this.upsellInCart) return;

      if (this.upsellInCart.quantity > 1) {
        this.updateCart({
          method: "put",
          url: `carts/${this.cart.id}/products/`,
          data: Object.assign(
            this.$utils.copy(this.upsellInCart),
            { quantity: this.upsellInCart.quantity - 1 },
            {
              choices: this.upsellInCart.choices.map((choice) => {
                return { id: choice.option_id, quantity: 1 };
              }),
            }
          ),
        });
      } else {
        if (this.location) {
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: "remove_from_cart",
            ecommerce: {
              value: parseFloat(this.upsellInCart.cost),
              currency: "USD",
              location_name: this.location.name,
              location_id: this.location.id,
              items: [{
                item_id: this.upsellInCart.id,
                item_name: this.upsellInCart.name,
              }],
            }
          })
        }

        await this.updateCart({
          method: "delete",
          url: `carts/${this.cart.id}/products/${this.upsellInCart.id}`,
        });
      }
    },
    async increaseQuantity() {
      if (!this.upsellInCart) return;

      await this.updateCart({
        method: "put",
        url: `carts/${this.cart.id}/products/`,
        data: Object.assign(
          this.$utils.copy(this.upsellInCart),
          { quantity: this.upsellInCart.quantity + 1 },
          {
            choices: this.upsellInCart.choices.map((choice) => {
              return { id: choice.option_id, quantity: 1 };
            }),
          }
        ),
      });

      if (this.location) {
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: "add_to_cart",
          ecommerce: {
            value: this.product.cost,
            currency: "USD",
            location_name: this.location.name,
            location_id: this.location.id,
            items: [{
              item_id: this.product.id,
              item_name: this.product.name,
              item_type: "Upsell",
            }],
          }
        })
      }
    },
    async updateCart(config) {
      this.setLoading(true);
      const response = await this.sendRequest(config);
      if (response.status === "ok") {
        this.setCart(response.data);
      }
      this.setLoading(false);
    },
    // onProductModify() {
    //   this.$router.push(
    //     this.isDefaultMenu
    //       ? `/order`
    //       : `/menu/${this.location?.menu_id}/product/${this.product.id}`
    //   );
    //   this.$emit("close-cart");
    // },
  },
  watch: {
    "product.isInCart": {
      handler(newValue) {
        if (newValue) {
          this.showAddedMessage = true;

          setTimeout(() => {
            this.showAddedMessage = false;
          }, 1000);
        }
      },
    },
  },
};
