import { render, staticRenderFns } from "./LocationConfirmModal.vue?vue&type=template&id=38af0964"
import script from "./LocationConfirmModal.vue?vue&type=script&lang=js"
export * from "./LocationConfirmModal.vue?vue&type=script&lang=js"
import style0 from "./LocationConfirmModal.vue?vue&type=style&index=0&id=38af0964&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconCloseGray: require('/opt/build/repo/components/icons/IconCloseGray.vue').default,IconDirections: require('/opt/build/repo/components/icons/IconDirections.vue').default,IconTimer: require('/opt/build/repo/components/icons/IconTimer.vue').default,AppButton: require('/opt/build/repo/components/common/AppButton.vue').default,IconChangeLocation: require('/opt/build/repo/components/icons/IconChangeLocation.vue').default})
