import { render, staticRenderFns } from "./default.vue?vue&type=template&id=1adab075"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=1adab075&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/opt/build/repo/components/globals/Loading.vue').default,Errors: require('/opt/build/repo/components/globals/Errors.vue').default,Header: require('/opt/build/repo/components/globals/Header.vue').default,Menu: require('/opt/build/repo/components/globals/Menu.vue').default,Footer: require('/opt/build/repo/components/globals/Footer.vue').default,Cart: require('/opt/build/repo/components/globals/Cart.vue').default,CateringDialogue: require('/opt/build/repo/components/account/CateringDialogue.vue').default})
