
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  data() {
    return {
      mods: []
    }
  },
  props: {
    product: {
      type: Object,
      default: () => { return {} }
    },
    position: {
      type: Number,
      default: 0
    },
  },
  computed: {
    ...mapState({
      choices: state => state.product.choices,
    }),
    ...mapState(['menu', 'cart', 'location']),
    backgroundImage() {
      let image = null
      this.menu?.categories.map(c => {
        c.products.map(p => {
          if (this.product.product_id === p.product_id || this.product.name === p.name) {
            if (p.images && p.images['marketplace-product']) {
              image = p.images['marketplace-product'].url
            }
          }
        })
      })
      return image || 'https://a.storyblok.com/f/250964/150x150/e749e5a558/product-placeholder.svg'
    },
    cost() {
      let cost = this.product.cost.base
      this.product.choices.map(choice => {
        cost += choice.cost
      })
      cost = cost * this.product.quantity
      return cost.toFixed(2)
    },
    cal() {
      let cal = null
      this.menu?.categories.map(c => {
        c.products.map(p => {
          if (this.product.product_id === p.product_id) {
            cal = p.nutrition?.calories?.base
          }
        })
      })
      return cal ? ` | ${cal} cal` : null
    },
    description() {
      let choices = []
      this.product.choices.map(c => {
        if (c.name == 'Customize Left' || c.name == 'Customize Right' || c.name == 'Customize Whole' || c.name == 'Additional Ingredients') {
          return
        }
        choices.push(`${c.name}${this.getSideFromChoice(c.option_id)}`)
      })
      const counts = []
      choices.forEach(x => {
        x = x.trim()
        counts[x] = (counts[x] || 0) + 1
      })
      choices.forEach((choice, i) => {
        if (counts[choice.trim()] > 1) {
          choices[i] = choices[i] + ' x' + counts[choice.trim()]
        }
      })
      choices = [...new Set(choices)]
      return choices.join(', ')
    },
    mobile() {
      return this.$mq == 'sm' || this.$mq == 'md'
    },
    isPDP() {
      return this.$route.fullPath.includes('product')
    },
  },
  async beforeMount() {
    const config = {
      method: 'get',
      url: `menus/${this.location.menu_id}/products/${this.product.product_id}`
    }
    const response = await this.sendRequest(config)
    if (response.status === 'ok') {
      this.mods = response.data.mods
    }
  },
  methods: {
    ...mapActions({
      validateCart: 'checkout/validateCart',
      getCart: 'getCart',
      sendRequest: 'sendRequest'
    }),
    ...mapMutations({
      setLoading: 'setLoading',
      setCart: 'setCart',
      setErrors: 'setErrors',
    }),
    getSideFromChoice(choice_id) {
      let side = ''
      if (!this.mods) return ''
      this.mods.forEach(mod => {
        mod.options.forEach(option => {
          option.mods.forEach(subMod => {
            if (subMod.name == 'Left') {
              if (JSON.stringify(subMod).includes(choice_id)) {
                side = ' (L)'
                return
              }
            }
            if (subMod.name == 'Right') {
              if (JSON.stringify(subMod).includes(choice_id)) {
                side = ' (R)'
                return
              }
            }
          })
        })
      })
      return side
    },
    async subtract() {
      if (this.product.quantity > 1) {
        this.updateCart({
          method: 'put',
          url: `carts/${this.cart.id}/products/`,
          data: Object.assign(
            this.$utils.copy(this.product),
            { quantity: this.product.quantity - 1 },
            {
              choices: this.product.choices.map(choice => {
                return { id: choice.option_id, quantity: 1 }
              })
            },
          )
        })
      } else {
        await this.updateCart({
          method: 'delete',
          url: `carts/${this.cart.id}/products/${this.product.id}`
        })

        if (this.location) {
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: "remove_from_cart",
            ecommerce: {
              value: this.product.cost.total * this.product.quantity,
              currency: "USD",
              location_name: this.location.name,
              location_id: this.location.id,
              items: [{
                item_id: this.product.product_id,
                item_name: this.product.name,
                quantity: this.product.quantity,
              }],
            }
          })
        }
      }
    },
    async add() {
      await this.updateCart({
        method: 'put',
        url: `carts/${this.cart.id}/products/`,
        data: Object.assign(
          this.$utils.copy(this.product),
          { quantity: this.product.quantity + 1 },
          {
            choices: this.product.choices.map(choice => {
              return { id: choice.option_id, quantity: 1 }
            })
          }
        )
      })

      if (this.location) {
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: "add_to_cart",
          ecommerce: {
            value: this.product.cost.total * this.product.quantity,
            currency: "USD",
            location_name: this.location.name,
            location_id: this.location.id,
            items: [{
              item_id: this.product.id,
              item_name: this.product.name,
            }],
          }
        })
      }

      if (this.$route.name === 'order-checkout') {
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
        event: 'begin_checkout',
        ecommerce: {
          currency: 'USD',
          value: this.cart.totals.subtotal,
          location_name: this.location.name,
          location_id: this.location.id,
          order_type: this.cart.handoff,
          items: this.cart.products.map(product => {
            return {
              item_id: product.id,
              item_name: product.name,
              quantity: product.quantity
            }
          })
        }
      })
      }
    },
    async updateCart(config) {
      this.setLoading(true)
      const response = await this.sendRequest(config)
      if (response.status === 'ok') {
        this.setCart(response.data)
        if (this.$route.name === 'order-checkout') {
          let invalidCart = await this.validateCart()
          if (invalidCart) {
            this.setErrors([invalidCart])
          } else {
            await this.getCart(this.cart.id)
          }
        }
      }
      this.setLoading(false)
    },
    editLink(p) {
      document.body.style.overflow = 'auto'
      this.$store.commit('setShowCart', false)
      const productEditPath = `/menu/${this.cart.menu_id}/product/${p.product_id}?edit=${p.product_id}&position=${this.position}`

      // check if this product is already in edit
      if (this.$route.fullPath === productEditPath) {
        return
      }

      // check if PDP is open to refresh the page
      if (this.isPDP) {
        this.$router.replace(productEditPath)
        return setTimeout(() => this.$router.go(), 200)
      }

      // redirect to the PDP page
      this.$router.push(productEditPath)
    },
  }
}
