
import { mapState, mapMutations, mapActions } from "vuex"

export default {
  computed: {
    ...mapState(['channel']),
    ...mapState({
      cateringDialogue: (state) => state.app.cateringDialogue,
    })
  },
  methods: {
    ...mapActions(['logoutUser']),
    ...mapMutations(['setChannel','setMenu','setLocation']),
    ...mapMutations({
      setCateringDialogue: "app/setCateringDialogue",
    }),
    close() {
      this.setCateringDialogue(false)
    },
    async startCatering() {
      let clear = await this.logoutUser()
      if (clear) {
        this.$store.commit('setFilteredMenu', [])
        this.$store.commit('setSearchedMenu', false)
        this.setMenu(null)
        this.setLocation(null)
        this.setChannel('catering')
        this.setCateringDialogue(false)
        this.$router.push('/locations')
      }
    },
    async exitCatering() {
      let clear = await this.logoutUser()
      if (clear) {
        this.$store.commit('setFilteredMenu', [])
        this.$store.commit('setSearchedMenu', false)
        this.setMenu(null)
        this.setLocation(null)
        this.setChannel('default')
        this.setCateringDialogue(false)
        this.$router.push('/locations')
      }
    }
  },
};
