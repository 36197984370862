
export default {
  props: {
    color: {
      default: () => {
        return "#BAF54D";
      },
    },
  },
};
