
import { mapState, mapMutations } from "vuex";
// import headerMenu from "~/assets/menus/header.json";

export default {
  computed: {
    ...mapState(["user", "location", "order", "cart", "showCart", "channel"]),
    ...mapState({
      showMenu: (state) => state.app.showMenu,
      hidingCart: (state) => state.app.hidingCart,
      hidingMenu: (state) => state.app.hidingMenu,
    }),
    // headerMenu() {
    //   return headerMenu;
    // },
    cartCount() {
      if (this.cart && this.cart.products) {
        return this.cart.products.reduce((acc, product) => {
          return acc + product.quantity;
        }, 0);
      }

      return 0;
    },
    shouldShow50AnniversaryLogo() {
      // start time: May 14, 2024 12:00:00 UTC (8:00 AM EST)
      const startTime = new Date('2024-05-14T12:00:00.000Z').getTime();
      // end time: October 28, 2024 04:00:00 UTC (12:00 AM EST)
      const endTime = new Date('2024-10-31T04:00:00.000Z').getTime();
      const currentTime = new Date().getTime();

      return currentTime >= startTime && currentTime <= endTime;
    },
    careersLink() {
      const jobLink = this.location?.c_joblink;

      if (!jobLink) {
        return {
          link: "/careers",
          target: "_self",
          type: "relative",
        };
      }

      const isRelative = jobLink.includes("https://mellowmushroom.com/");
      if (isRelative) {
        return {
          link: jobLink
            .replace(/https:\/\/mellowmushroom\.com\//g, "/")
            .replace("/mellow-jobs/apply/", "/careers"),
          target: "_self",
          type: "relative",
        };
      }
      return {
        link: this.location.c_joblink,
        target: "_blank",
        type: "absolute",
      };
    },
  },
  methods: {
    ...mapMutations({
      setShowMenu: "app/setShowMenu",
      setHidingCart: "app/setHidingCart",
      setHidingMenu: "app/setHidingMenu",
      setCateringDialogue: "app/setCateringDialogue",
    }),
    handleExternalNavigation() {
      if (this.careersLink.type === 'absolute') {
        window.open(this.careersLink.link, '_blank');
      }
    },
    isActive(page) {
      if (page == 'catering') {
        if (this.$route.path.split('/')[1] !== 'locations') {
          return this.channel == 'catering'
        }
      }
      if (page == 'order') {
        if (this.channel !== 'catering') {
          return this.$route.path.split('/')[1] == 'menu'
        } else {
          return false
        }
      }
      return page == this.$route.path.split('/')[1]
    },
    startMealtime() {
      console.log(this.channel)
      if (this.channel == 'catering') {
        this.setCateringDialogue(true)
      } else {
        this.$router.push('/order')
      }
    },
    startCatering() {
      if (this.channel == 'catering') {
        this.$router.push('/order')
      } else {
        this.$router.push('/catering')
      }
    },
    toggleCart() {
      if (this.showCart) {
        this.setHidingCart(true);
        setTimeout(
          function () {
            document.body.style.overflow = "auto";
            this.$store.commit("setShowCart", false);
            this.setHidingCart(false);
          }.bind(this),
          500
        );
      } else {
        document.body.style.overflow = "hidden";
        this.$store.commit("setShowCart", true);
      }
    },
    toggleMenu() {
      if (this.showMenu) {
        this.setHidingMenu(true);
        setTimeout(
          function () {
            this.setShowMenu(false);
            this.setHidingMenu(false);
          }.bind(this),
          500
        );
      } else {
        this.setShowMenu(true);
      }
    },
  },
};
