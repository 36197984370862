
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      makeField: {
        name: "make",
        type: "text",
        value: "",
        placeholder: "Make",
        required: true,
        errors: {
          invalid: "Please enter a vehicle make",
        },
      },
      modelField: {
        name: "model",
        type: "text",
        value: "",
        placeholder: "Model",
        required: true,
        errors: {
          invalid: "Please enter a vehicle model",
        },
      },
      colorField: {
        name: "color",
        type: "text",
        value: "",
        placeholder: "Color",
        required: true,
        errors: {
          invalid: "Please enter a vehicle color",
        },
      },
    };
  },
  mounted() {
    if (this.vehicleInfo) {
      this.updateForm();
    }

    if (this.cart && this.cart.custom) {
      this.makeField.value = this.cart.custom.find(
        (item) => item.label === "Make"
      ).value;

      this.modelField.value = this.cart.custom.find(
        (item) => item.label === "Model"
      ).value;

      this.colorField.value = this.cart.custom.find(
        (item) => item.label === "Color"
      ).value;
    }
  },
  computed: {
    ...mapState(["cart"]),
    ...mapState({
      vehicleInfo: (state) => state.locations.vehicleInfo,
    }),
  },
  methods: {
    ...mapActions(["getCart"]),
    ...mapActions({
      applyCartHandoff: "checkout/applyCartHandoff",
    }),
    ...mapMutations({
      setVehicleInfo: "locations/setVehicleInfo",
    }),
    async handleSubmit() {
      this.setVehicleInfo({
        make: this.makeField.value,
        model: this.modelField.value,
        color: this.colorField.value,
      });

      await this.applyCartHandoff({
        Make: this.makeField.value,
        Model: this.modelField.value,
        Color: this.colorField.value,
        handoffMode: "curbside",
      });

      await this.getCart();

      this.$router.push("/order");
    },
    updateForm() {
      this.makeField.value = this.vehicleInfo.make;
      this.modelField.value = this.vehicleInfo.model;
      this.colorField.value = this.vehicleInfo.color;
    },
  },
  watch: {
    vehicleInfo() {
      this.updateForm();
    },
  },
};
