import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _31186d84 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _953bbfa8 = () => interopDefault(import('../pages/catering/index.vue' /* webpackChunkName: "pages/catering/index" */))
const _16ddd532 = () => interopDefault(import('../pages/locations/index.vue' /* webpackChunkName: "pages/locations/index" */))
const _5b71908c = () => interopDefault(import('../pages/menu/index.vue' /* webpackChunkName: "pages/menu/index" */))
const _2c62ff52 = () => interopDefault(import('../pages/order/index.vue' /* webpackChunkName: "pages/order/index" */))
const _8081fc14 = () => interopDefault(import('../pages/locations/all.vue' /* webpackChunkName: "pages/locations/all" */))
const _c7a1a85e = () => interopDefault(import('../pages/order/checkout.vue' /* webpackChunkName: "pages/order/checkout" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0d3c9bf6 = () => interopDefault(import('../pages/order/confirm/_oid.vue' /* webpackChunkName: "pages/order/confirm/_oid" */))
const _63883c82 = () => interopDefault(import('../pages/menu/_mid/index.vue' /* webpackChunkName: "pages/menu/_mid/index" */))
const _ac124d28 = () => interopDefault(import('../pages/menu/_mid/product/_pid.vue' /* webpackChunkName: "pages/menu/_mid/product/_pid" */))
const _991ffde8 = () => interopDefault(import('../pages/locations/_state/_city/_address.vue' /* webpackChunkName: "pages/locations/_state/_city/_address" */))
const _b186cf36 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _31186d84,
    name: "404"
  }, {
    path: "/catering",
    component: _953bbfa8,
    name: "catering"
  }, {
    path: "/locations",
    component: _16ddd532,
    name: "locations"
  }, {
    path: "/menu",
    component: _5b71908c,
    name: "menu"
  }, {
    path: "/order",
    component: _2c62ff52,
    name: "order"
  }, {
    path: "/locations/all",
    component: _8081fc14,
    name: "locations-all"
  }, {
    path: "/order/checkout",
    component: _c7a1a85e,
    name: "order-checkout"
  }, {
    path: "/",
    component: _0547af50,
    name: "index"
  }, {
    path: "/order/confirm/:oid?",
    component: _0d3c9bf6,
    name: "order-confirm-oid"
  }, {
    path: "/menu/:mid",
    component: _63883c82,
    name: "menu-mid"
  }, {
    path: "/menu/:mid/product/:pid?",
    component: _ac124d28,
    name: "menu-mid-product-pid"
  }, {
    path: "/locations/:state/:city?/:address?",
    component: _991ffde8,
    name: "locations-state-city-address"
  }, {
    path: "/*",
    component: _b186cf36,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
