import { render, staticRenderFns } from "./Upsells.vue?vue&type=template&id=84e5b4dc&scoped=true"
import script from "./Upsells.vue?vue&type=script&lang=js"
export * from "./Upsells.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84e5b4dc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UpsellItem: require('/opt/build/repo/components/globals/UpsellItem.vue').default})
