
import { mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
  computed: {
    ...mapState(["cart", "order", "location", "channel"]),
    ...mapState({
      showLocationDropdown: (state) => state.app.showLocationDropdown,
      hidingLocationDropdown: (state) => state.app.hidingLocationDropdown,
      showLocationDeliveryForm: (state) => state.app.showLocationDeliveryForm,
    }),
    iconColor() {
      let color = "#BAF54D";
      if (this.$mq == "sm" || this.$mq == "md") {
        color = "#3D098E";
      }
      return color;
    },
    googleMapsLocationUrl() {
      const zip = this.location.zip ? this.location.zip : "";
      const { address, city, state } = this.location;

      return `https://www.google.com/maps/search/?api=1&query=Mellow%20Mushroom+${address}+${city}+${state}+${zip}`;
    },
    fullLocationAddress() {
      const zip = this.location.zip ? `, ${this.location.zip}` : "";
      return `${this.location.address}, ${this.location.city}, ${this.location.state}${zip}`;
    },
    isPickup() {
      return this.cart.handoff === "pickup" && !this.showLocationDeliveryForm;
    },
    isCurbside() {
      return this.cart.handoff === "curbside" && !this.showLocationDeliveryForm;
    },
    isDelivery() {
      return this.cart.handoff === "delivery" || this.showLocationDeliveryForm;
    },
    shouldRenderDeliveryForm() {
      return this.showLocationDeliveryForm;
    },
    shouldShowDeliveringToLabel() {
      return this.isDelivery && !this.showLocationDeliveryForm;
    },
    todayHours() {
      const dayOfWeek = parseInt(moment().format("e"));
      return this.location?.hours[dayOfWeek];
    },
    isTempClosed() {
      return this.todayHours?.closed;
    },
    isComingSoon() {
      return this.location?.c_inDevelopment || this.location?.c_indevelopment;
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    document.addEventListener("keydown", this.handleKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    ...mapMutations({
      setShowLocationDropdown: "app/setShowLocationDropdown",
      setHidingLocationDropdown: "app/setHidingLocationDropdown",
      setCateringDialogue: "app/setCateringDialogue",
      setShowLocationDeliveryForm: "app/setShowLocationDeliveryForm",
    }),
    exitCatering() {
      this.setCateringDialogue(true);
    },
    changeLocation() {
      this.setShowLocationDropdown(false);
      if (this.$route.path.replace(/\//g, "") !== "locations") {
        this.$router.push("/locations");
      }
    },
    handleClickOutside(event) {
      if (
        this.showLocationDropdown &&
        !this.$el.contains(event.target) &&
        event.target !== this.$el
      ) {
        if (!event.target.getAttribute("data-prevent-dropdown-close")) {
          this.setShowLocationDropdown(false);
        }
      }
    },
    handleKeyDown(event) {
      if (event.key === "Escape" && this.showLocationDropdown) {
        this.setShowLocationDropdown(false);
      }
    },
  },
};
