
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      selectedMethod: "pickup",
    };
  },
  computed: {
    ...mapState(["cart", "order", "location","channel"]),
    ...mapState({
      showLocationDropdown: (state) => state.app.showLocationDropdown,
      hidingLocationDropdown: (state) => state.app.hidingLocationDropdown,
    }),
    iconColor() {
      let color = "#BAF54D";
      if (this.$mq == "sm" || this.$mq == "md") {
        color = "#3D098E";
      }
      return color;
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    document.addEventListener("keydown", this.handleKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    ...mapMutations({
      setShowLocationDropdown: "app/setShowLocationDropdown",
      setHidingLocationDropdown: "app/setHidingLocationDropdown",
    }),
    selectPickup() {
      this.selectedMethod = "pickup";
      this.setShowLocationDropdown(false);
      this.runMethod()
    },
    selectDelivery() {
      this.selectedMethod = "delivery";
      this.setShowLocationDropdown(true);
      this.runMethod()
    },
    runMethod() {
      if (this.selectedMethod === "pickup") {
        this.$router.push("/locations");
      } 
      // else {
      //   this.setShowLocationDropdown(!this.showLocationDropdown);
      // }
    },
    handleClickOutside(event) {
      if (
        this.showLocationDropdown &&
        !this.$el.contains(event.target) &&
        event.target !== this.$el
      ) {
        this.setShowLocationDropdown(false);
      }
    },
    handleKeyDown(event) {
      if (event.key === "Escape" && this.showLocationDropdown) {
        this.setShowLocationDropdown(false);
      }
    },
    goToCatering() {
      this.$router.push('/catering')
    }
  },
};
