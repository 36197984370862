// Load Typekit fonts asynchronously to prevent render blocking
export default function () {
  const config = { kitId: 'exn7zzq' };
  const d = document;
  const tkScript = d.createElement('script');
  tkScript.src = `https://use.typekit.net/${config.kitId}.js`;
  tkScript.async = true;
  tkScript.onload = function () {
    try {
      window.Typekit.load(config);
    } catch (e) {
      console.error('Error loading Typekit:', e);
    }
  };
  d.head.appendChild(tkScript);
}