import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'HandoffMixin',
  computed: {
    ...mapState([ 'cart' ]),
    ...mapState({
      showLocationDropdown: (state) => state.app.showLocationDropdown,
      deliveryAddress: (state) => state.locations.deliveryAddress,
    }),
  },
  methods: {
    ...mapMutations([ 'setErrors' ]),
    ...mapMutations({
      setShowLocationDropdown: "app/setShowLocationDropdown",
      setShowLocationDeliveryForm: "app/setShowLocationDeliveryForm",
      setHandoffMode: 'order/setHandoffMode',
      setDeliveryAddress: 'locations/setDeliveryAddress',
    }),
    ...mapActions([ 'getCart', 'getDeliveryLocations' ]),
    ...mapActions({
      applyCartHandoff: 'checkout/applyCartHandoff',
    }),
    async setHandoff(key) {
      this.setHandoffMode(key)

      if (key === 'pickup') {
        this.setPickup()
      }
      if (key === 'curbside') {
        this.setCurbside()
      }
      if (key === 'delivery') {
        this.setDelivery()
      }
    },
    async setPickup() {
      if (this.cart) {
        await this.applyCartHandoff({ handoffMode: 'pickup' })
        await this.getCart()
        this.setShowLocationDeliveryForm(false)
      }
    },
    async setCurbside() {
      if (this.cart) {
        await this.applyCartHandoff({ handoffMode: 'curbside' })
        await this.getCart()
        this.checkLocationDropdown()
        this.setShowLocationDeliveryForm(false)
      }
    },
    async setDelivery() {
      console.log('*** here')
      this.checkLocationDropdown()

      if (this.deliveryAddress && this.deliveryAddress.address) {
        const location = await this.getDeliveryLocations({
          address: this.deliveryAddress.address,
          city: this.deliveryAddress.city,
          zip: this.deliveryAddress.zip,
          state: this.deliveryAddress.state,
          lat: this.deliveryAddress.lat,
          lng: this.deliveryAddress.lng,
        })

        if (location === null) {
          this.setErrors([])
          this.setDeliveryAddress(null)
          this.setDelivery()
          // return this.redirectToLocationSearch()
          return false
        }

        if (this.cart) {
          await this.applyCartHandoff({
            address: this.deliveryAddress.address,
            building: this.deliveryAddress.building,
            city: this.deliveryAddress.city,
            state: this.deliveryAddress.state,
            zip: this.deliveryAddress.zip,
            // phone: this.deliveryAddress.phone,
            // instructions: this.deliveryAddress.instructions,
            default: false,
            handoffMode: 'delivery',
          })

          await this.getCart()
        }
      }
      
      this.setShowLocationDeliveryForm(true)
    },
    checkLocationDropdown() {
      if (!this.showLocationDropdown) {
        this.setShowLocationDropdown(true)
      }
    },
  },
}
