
import { mapState, mapMutations, mapActions } from 'vuex'
import moment from "moment";

export default {
  data() {
    return {
      showDeliveryForm: false,
      showUpsells: false,
    };
  },
  props: {
    checkoutPage: {
      type: Boolean,
      default: false
    },
    onDeliveryToggle: {
      type: Function,
      default: () => { }
    }
  },
  computed: {
    ...mapState(['showCart', 'cart', 'order', 'location', 'menu', 'channel', 'user', 'token']),
    ...mapState({
      showLocationDeliveryForm: state => state.app.showLocationDeliveryForm,
      hidingCart: state => state.app.hidingCart,
      upsells: (state) => state.checkout.upsells,
    }),
    isDelivery() {
      return this.cart.handoff === "delivery";
    },
    mobile() {
      return this.$mq == 'sm' || this.$mq == 'md'
    },
    estimatedReadyTime() {
      const earliestTime = this.cart?.time?.earliest || '';
      const formattedTime = moment(earliestTime, 'YYYYMMDD HH:mm').format('h:mm A [on] MM/DD/YYYY');
      return formattedTime;
    },
    showEstimatedTime() {
      return this.order.orderMode !== 'advance' && this.estimatedReadyTime
    },
    subtotal() {
      return this.cart?.totals?.subtotal.toFixed(2) || 0
    },
    shouldRenderDeliveryForm() {
      return this.showLocationDeliveryForm;
    },
    shouldShowUpsells() {
      // return this.showUpsells && this.upsells.length && !this.checkoutPage
      return this.showUpsells && this.staticUpsells.length && !this.checkoutPage
    },
    isUpsellInCart() {
      // return this.upsells.some(upsell => this.cart.products.some(product => product.product_id === upsell.product_id))
      return this.staticUpsells.some(upsell => this.cart.products.some(product => product.product_id === upsell.product_id))
    },
    checkoutButtonText() {
      if (this.shouldShowUpsells && !this.isUpsellInCart) {
        return 'No, Thanks'
      } else {

        return this.shouldShowUpsells ? 'Proceed to Checkout' : `Proceed to Checkout (${this.subtotal})`
      }
    },
    staticUpsells() {
      const upsellCategory = this.menu.categories.find(category => category.name.toLowerCase() === 'upsells')

      if (upsellCategory) {
        return upsellCategory.products
      }

      const upsellItemNames = [
        "Spicy Beer Cheese",
        "Pretzel Bites",
        "Ranch Side",
        "Triple Chocolate Chunk Cookie"
      ]

      const staticUpsells = this.menu.categories.reduce((acc, category) => {
        const upsells = category.products.filter(product => upsellItemNames.includes(product.name))
        return [...acc, ...upsells]
      }, [])

      return staticUpsells
    }
  },
  async mounted() {
    if (!this.checkoutPage) {
      this.$gtm.push({ ecommerce: null })
      this.$gtm.push({
        event: 'view_cart',
        ecommerce: {
          currency: 'USD',
          value: this.cart.totals.subtotal,
          location_name: this.location.name,
          location_id: this.location.id,
          items: this.cart.products.map(product => {
            return {
              item_id: product.product_id,
              item_name: product.name,
              quantity: product.quantity
            }
          })
        }
      })
    }

    if (this.cart && !this.menu) {
      const config = {
        method: 'get',
        url: `menus/${this.cart.menu_id}`
      }
      const response = await this.sendRequest(config)
      if (response.status === 'ok') {
        this.setMenu(response.data)
      }
    }

    // await this.getUpsells()
  },
  methods: {
    ...mapMutations({
      setMenu: 'setMenu',
      setShowMenu: 'app/setShowMenu',
      setHidingCart: 'app/setHidingCart',
      setCateringDialogue: "app/setCateringDialogue",
      setLoading: 'setLoading',
      setShowLocationDeliveryForm: "app/setShowLocationDeliveryForm",
      setUpsells: 'checkout/setUpsells',
    }),
    ...mapActions([ 'abandonCart' ]),
    ...mapActions({
      sendRequest: 'sendRequest',
      getCartUpsells: 'checkout/getCartUpsells',
    }),
    hideCart() {
      this.setHidingCart(true)
      setTimeout(function () {
        document.body.style.overflow = 'auto'
        this.$store.commit('setShowCart', false)
        this.setHidingCart(false)
      }.bind(this), 500)
    },
    toggleDeliveryForm(show = true) {
      this.$utils.log('toggleDeliveryForm', show)
      this.onDeliveryToggle(show)
      this.setShowLocationDeliveryForm(show)
      if (show) this.$nuxt.$emit('showAddress', true)
    },
    address(location) {
      return location.address + ', ' + location.city + ', ' + location.state + ', ' + location.zip
    },
    changeLocation() {
      // this.setShowLocationDropdown(false)
      if (this.$route.name !== 'locations') {
        this.$router.push('/locations')
      }
      this.hideCart()
      this.closeLocationConfirmModal()
    },
    exitCatering() {
      this.setCateringDialogue(true)
    },
    openLocationConfirmModal() {
      this.$modal.show("location-confirm-modal");
    },
    closeLocationConfirmModal() {
      this.$modal.hide("location-confirm-modal");
    },
    handleOnCheckout() {
      if (this.showUpsells) {
        this.openLocationConfirmModal()
      } else {
        this.showUpsells = true
      }
    },
    goToCheckout() {
      this.hideCart()
      this.$router.push('/order/checkout')
    },
    async clearCart() {
      if (this.location) {
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: "remove_from_cart",
          ecommerce: {
            value: this.cart.totals.subtotal,
            currency: "USD",
            location_name: this.location.name,
            location_id: this.location.id,
            items: this.cart.products.map(product => ({
              item_name: product.name,
              item_id: product.id,
            }))
          }
        })
      }

      this.setLoading(true)
      await this.abandonCart()
      this.hideCart()
      this.setLoading(false)
    },
    addMoreItems() {
      this.$router.push(`/order`)
      this.hideCart()
    },
    async getUpsells() {
      try {
        this.setLoading(true)

        const upsellCategories = {
          Extras: { id: '84095', itemsToGet: 4, itemsToShow: 2}, // Getting 4 extras to hide the ones we don't want to show, but we need to show 2
          Sweets: { id: '45982', itemsToGet: 1 },
          Drinks: { id: '84097', itemsToGet: 6, itemsToShow: 1 }, // Getting 6 drinks to hide the ones we don't want to show, but we need to show 1
        }

        // Client asked to hide these items from the upsells
        const idsOfItemsToHide = {
          REDBULL: '695272',
          REDBULL_SUGARFREE: '695274',
          GALLON_LEMONADE: '692311',
          GALLON_SWEET_TEA: '692309',
          GALLON_UNSWEET_TEA: '692310',
          REGULAR_CHIPS: '692326',
          BBQ_CHIPS: '692327',
        }

        const requests = Object.values(upsellCategories).map(({ id, itemsToGet }) => {
          const params = { category: id, num: itemsToGet }

          if (!this.user && !this.token) {
            params.pid = this.cart.products[0].product_id
          }

          const config = {
            method: 'get',
            url: `carts/${this.cart.id}/products`,
            params
          }

          return this.sendRequest(config)
        })

        const responses = await Promise.all(requests)

        const upsells = responses.reduce((acc, response) => {
          if (response.status === 'ok') {
            const { products } = response.data

            const categoriesToHideSomeItems = ['Drinks', 'Extras']

            if (categoriesToHideSomeItems.includes(products[0]?.category)) {
              const filteredProducts = products.filter(product => !Object.values(idsOfItemsToHide).includes(product.id))

              const upsellSampleCategory = upsellCategories[products[0]?.category]
              if (upsellSampleCategory?.itemsToShow) {
                acc.push(...filteredProducts.slice(0, upsellSampleCategory?.itemsToShow))
              } else {
                acc.push(...filteredProducts)
              }
            } else {
              acc.push(...products)
            }
          }
          return acc
        }, [])

        this.setUpsells(upsells)
      } catch (error) {
        console.log(error)
      } finally {
        this.setLoading(false)
      }
    },
  },
}
