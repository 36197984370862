import { render, staticRenderFns } from "./UpsellItem.vue?vue&type=template&id=99807006&scoped=true"
import script from "./UpsellItem.vue?vue&type=script&lang=js"
export * from "./UpsellItem.vue?vue&type=script&lang=js"
import style0 from "./UpsellItem.vue?vue&type=style&index=0&id=99807006&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99807006",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconGlutenFree: require('/opt/build/repo/components/icons/IconGlutenFree.vue').default,IconVegetarian: require('/opt/build/repo/components/icons/IconVegetarian.vue').default,IconVegan: require('/opt/build/repo/components/icons/IconVegan.vue').default,IconPlusCircle: require('/opt/build/repo/components/icons/IconPlusCircle.vue').default,IconMinus: require('/opt/build/repo/components/icons/IconMinus.vue').default,IconPlus: require('/opt/build/repo/components/icons/IconPlus.vue').default})
