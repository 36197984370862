
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState(["errors", "appInfo"]),
    errorList() {
      if (this.errors) {
        if (Array.isArray(this.errors)) {
          return this.errors;
        } else if (typeof this.errors === "object") {
          return this.errors.messages;
        } else {
          return [this.errors];
        }
      }
      return [];
    },
    modalHeading() {
      if (this.errors?.heading === false) {
        return false;
      }

      return this.errors?.heading || "Error";
    },
  },
  methods: {
    ...mapMutations(["setErrors"]),
    close() {
      this.setErrors([]);
    },
  },
};
