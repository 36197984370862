
export default {
  props: {
    color: {
      default: () => {
        return "white";
      },
    },
  },
};
