
  import { mapState, mapMutations, mapActions } from 'vuex'

  export default {
    data() {
      return {
        number: ''
      }
    },
    computed: {
      ...mapState(['cart']),
    },
    methods: {
      ...mapMutations({
        setLoading: 'setLoading',
      }),
      ...mapActions({
        setCart: 'setCart',
        sendRequest: 'sendRequest'
      }),
      updateNumber() {
        this.setLoading(true)
        let data = [...this.cart.custom]
        data.push({
          label: 'Number of People',
          value: this.number,
        })
        console.log(this.cart)
        // const config = {
        //   method: 'put',
        //   url: `carts/${this.cart.id}/custom`,
        //   data
        // }
        // const response = this.sendRequest(config)
        // if (response.status === 'ok') {
        //   this.setCart(response.data)
        // }
        this.setLoading(false)
      }
    }
  }
