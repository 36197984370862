
export default {
  name: "InputDefault",
  props: {
    field: {
      type: Object,
      default: {
        name: "",
        label: "This is a checkbox",
        message: "",
        type: "checkbox",
        showTerms: false,
        required: false,
        value: false,
        errors: {
          invalid: "Checkbox must be selected.",
        },
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    validate() {
      let valid = this.field.required ? this.field.value : true;
      this.field.error = valid ? null : this.field.errors.invalid;
      return valid;
    },
    handleKeyup(e) {
      if (this.field.touched) {
        this.validate();
      }
      if (this.field.max && this.field.value.length > this.field.max) {
        this.field.value = this.field.value.slice(0, -1);
      }
    },
    handleBlur(e) {
      this.field.touched = true;
      this.validate();
    },
  },
};
