
import { mapState, mapMutations, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "OrderTimePicker",
  props: {
    layout: {
      type: String,
      deafult: "column",
    },
  },
  data() {
    return {
      deliveryDays: [],
      times: [],
      day: null,
      time: null,
      // disableAsap: false,
      defaultAsapUnMsg: {
        show: false,
        copy: "Location is currently closed, you can schedule an order or come back later",
        nextAvailableTime: null,
        timeout: 5000,
      },
      oloHandoffTimes: [],
    };
  },
  async mounted() {
    await this.getHandoffTimes()

    const advanceOrderDays =
      this.location && this.location?.services?.advancedays
        ? this.location?.services?.advancedays
        : 14;

    let i = 0;
    while (i < advanceOrderDays) {
      const day = moment().add(i, "days");
      this.deliveryDays.push({
        dayOfWeek: day.format("ddd"),
        dayOfMonth: day.format("D"),
        fullDate: day.format("YYYYMMDD"),
      });
      i++;
    }
    if (this.isOpenAccordingToOlo) {
      this.$utils.log("store is open");

      if (this.cart && this.cart.time) {
        if (this.cart.time.mode === "asap") {
          this.setOrderMode("asap");
        } else {
          this.setOrderMode("advance");
          if (this.cart.time.wanted) {
            this.updateDayAndTime(this.cart.time.wanted);
          }
        }
      }
    } else {
      this.$utils.log("store not open");
      // this.disableAsap = true;
      this.setOrderMode("advance");

      if (this.cart && this.cart.time) {
        if (this.cart.time.mode === "asap") {
          this.showAsapUnMsg();
        } else if (this.cart.time.wanted) {
          this.updateDayAndTime(this.cart.time.wanted);
        }
      } else {
        this.showAsapUnMsg();
      }
    }

    if (!this.cart && this.selectedDay !== null) {
      this.day = this.selectedDay;
      await this.getTime(this.selectedDay);
      this.time = this.selectedTime ? this.selectedTime : null;
    }
  },
  computed: {
    ...mapState(["location", "cart"]),
    ...mapState({
      orderMode: (state) => state.order.orderMode,
      selectedDay: (state) => state.order.selectedDay,
      selectedTime: (state) => state.order.selectedTime,
      asapUnavailable: (state) => state.order.asapUnavailable,
    }),
    showLocClosed() {
      if (this.asapUnavailable && this.asapUnavailable.disabled) {
        return false;
      }

      return this.defaultAsapUnMsg.show;
    },
    locClosedChip() {
      return this.asapUnavailable ? this.asapUnavailable.chip : true;
    },
    locClosedTitle() {
      return this.asapUnavailable && this.asapUnavailable.title
        ? this.asapUnavailable.title
        : "";
    },
    locClosedCopy() {
      return this.asapUnavailable && this.asapUnavailable.copy
        ? this.asapUnavailable.copy
        : this.defaultAsapUnMsg.copy;
    },
    locClosedTimeout() {
      return this.asapUnavailable && this.asapUnavailable.timeout
        ? this.asapUnavailable.timeout
        : this.defaultAsapUnMsg.timeout;
    },
    nowTimeAtLocation() {
      const localUtcOffset = moment().utcOffset() / 60;
      const locationUtcOffset = this.location.utcoffset;
      const absoluteLocalUtcOffset = Math.abs(localUtcOffset);
      const absoluteLocationUtcOffset = Math.abs(locationUtcOffset);
      const difference = localUtcOffset - locationUtcOffset;

      let now = moment();
      if (absoluteLocalUtcOffset < absoluteLocationUtcOffset) {
        // local time is ahead
        now = moment().subtract(difference, "hours");
      } else if (absoluteLocalUtcOffset > absoluteLocationUtcOffset) {
        // local time is behind
        now = moment().add(difference, "hours");
      }

      return now;
    },
    isOpenAccordingToOlo() {
      if (this.oloHandoffTimes.length === 0) return false;

      const now = this.nowTimeAtLocation;
      const todaysHandoffTimes = this.oloHandoffTimes.find(
        (day) => day.date === now.format("YYYYMMDD")
      );

      if (!todaysHandoffTimes) return false;

      const open = todaysHandoffTimes.from ? moment(todaysHandoffTimes.from, "HH:mm") : null;
      const close = todaysHandoffTimes.to ? moment(todaysHandoffTimes.to, "HH:mm") : null;

      if (!open || !close) return false;

      return now.isBetween(open, close);
    },
  },
  methods: {
    ...mapActions({
      sendRequest: "sendRequest",
      applyCartOrderAhead: "checkout/applyCartOrderAhead",
      applyCartOrderAsap: "checkout/applyCartOrderAsap",
      getNextAvailableTime: "checkout/getNextAvailableTime",
      validateCart: "checkout/validateCart",
      getCart: "getCart",
    }),
    ...mapMutations({
      setErrors: "setErrors",
      setLoading: "setLoading",
      setOrderMode: "order/setOrderMode",
      setSelectedDay: "order/setSelectedDay",
      setSelectedTime: "order/setSelectedTime",
    }),
    async getHandoffTimes() {
      const config = {
        method: "get",
        url: `menus/${this.location.menu_id}/times`,
        params: {
          from: moment().format('YYYYMMDD'),
          to: moment().format('YYYYMMDD'),
        }
      };
      const response = await this.sendRequest(config);

      const handoffTypes = {
        curbside: "curbsidepickup",
        pickup: "default",
        delivery: "dispatch"
      }

      const handoffType = handoffTypes[this.cart?.handoff] ?? "default"

      if (response.status === "ok") {
        this.oloHandoffTimes = response.data?.[handoffType]?.days
      } else {
        this.oloHandoffTimes = []
      }
    },
    async showAsapUnMsg() {
      const nextAvailableTime = await this.getNextAvailableTime();
      if (nextAvailableTime && nextAvailableTime.next) {
        console.log("** Next Available Time **", nextAvailableTime.next);
        // const nextTimeUtc = moment(nextAvailableTime.next, 'YYYYMMDD HH:mm').utc().add(this.location?.utcOffset ?? 0, 'hours').format('YYYYMMDD HH:mm')
        const nextTimeUtc = moment(
          nextAvailableTime.next,
          "YYYYMMDD HH:mm"
        ).format("YYYYMMDD HH:mm");
        this.defaultAsapUnMsg.nextAvailableTime = moment(
          nextTimeUtc,
          "YYYYMMDD HH:mm"
        ).format("h:mm A");
        this.updateDayAndTime(nextTimeUtc, false);
        await this.applyCartOrderAhead(nextAvailableTime.next);
      }

      // shows up the ASAP Unavailable message
      this.defaultAsapUnMsg.show = true;

      // fades the ASAP Unavailable message
      setTimeout(() => {
        this.defaultAsapUnMsg.show = false;
      }, this.locClosedTimeout);
    },
    async updateDayAndTime(datestring, showAsapUnMsg = true) {
      if (!datestring) return null;
      if (typeof datestring !== "string") return null;

      for (let d = 0; d < this.deliveryDays.length; d++) {
        if (datestring.includes(this.deliveryDays[d].fullDate)) {
          this.day = d;
          this.setSelectedDay(this.day);
          await this.getTime(this.day);

          for (let t = 0; t < this.times.length; t++) {
            const cartTime = moment(datestring, "YYYYMMDD HH:mm").format(
              "h:mm A"
            );
            if (cartTime === this.times[t]) {
              this.time = this.times[t];
              this.setSelectedTime(this.time);
              break;
            }
          }
        }
      }

      if (showAsapUnMsg && (this.day === null || !this.time)) {
        // day and time not found, show asap message
        // this.showAsapUnMsg()
      }
    },
    getDay(day) {
      const newDay = moment(`${day.fullDate}`, "YYYYMMDD").format(
        "MMM D, YYYY"
      );

      return newDay;
    },
    async getTime(d) {
      this.times = [];
      this.setLoading(true);

      if (this.cart?.id) {
        const config = {
          method: "get",
          url: `carts/${this.cart.id}/time`,
          params: {
            date: this.deliveryDays[d].fullDate,
          },
        };
        const response = await this.sendRequest(config);
        if (response.status === "ok") {
          response.data.map((t) => {
            this.times.push(moment(t, "YYYYMMDD HH:mm").format("h:mm A"));
          });
        }
      }

      this.setLoading(false);
    },
    async setTime() {
      this.setSelectedDay(this.day);
      this.setSelectedTime(this.time);
      const day = this.deliveryDays[this.day];
      if (day && this.time) {
        const time = moment(
          `${day.fullDate} ${this.time}`,
          "YYYYMMDD h:mm a"
        ).format("YYYYMMDD HH:mm");
        await this.applyCartOrderAhead(time);
        if (this.$route.name === "order-checkout") {
          let invalidCart = await this.validateCart();
          if (invalidCart) {
            this.setErrors([invalidCart]);

            this.$gtm.push({
              event: "throttle",
              store_id: parseInt(this.location.id),
              store_name: this.location.name,
              throttle: invalidCart,
            });
          } else {
            await this.getCart(this.cart.id);
          }
        }
      }
    },
    async setAsap() {
      await this.getHandoffTimes()

      if (!this.isOpenAccordingToOlo) {
        this.showAsapUnMsg();
        return;
      }

      this.setOrderMode("asap");
      await this.applyCartOrderAsap();

      if (this.$route.name === "order-checkout") {
        let invalidCart = await this.validateCart();
        if (invalidCart) {
          this.setErrors([invalidCart]);

          this.$gtm.push({
            event: "throttle",
            store_id: parseInt(this.location.id),
            store_name: this.location.name,
            throttle: invalidCart,
          });
        } else {
          await this.getCart(this.cart.id);
        }
      }
    },
  },
};
