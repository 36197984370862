import { render, staticRenderFns } from "./CartItem.vue?vue&type=template&id=163cc75a"
import script from "./CartItem.vue?vue&type=script&lang=js"
export * from "./CartItem.vue?vue&type=script&lang=js"
import style0 from "./CartItem.vue?vue&type=style&index=0&id=163cc75a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconMinus: require('/opt/build/repo/components/icons/IconMinus.vue').default,IconTrash: require('/opt/build/repo/components/icons/IconTrash.vue').default,IconPlus: require('/opt/build/repo/components/icons/IconPlus.vue').default,IconPencil: require('/opt/build/repo/components/icons/IconPencil.vue').default})
