// plugins/vue-js-modal.js
import Vue from "vue";
import VModal from "vue-js-modal/dist/ssr.nocss";

import "vue-js-modal/dist/styles.css";

Vue.use(VModal);

export default function(context, inject) {
  inject("modal", Vue.prototype.$modal);
  context.$modal = Vue.prototype.$modal;
}
