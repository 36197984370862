import us_states from "~/assets/data/us_states.json";

export const state = () => ({
  globals: {},
  showMenu: false,
  hidingMenu: false,
  hidingCart: false,
  showLocationDropdown: false,
  showLocationDeliveryForm: false,
  hidingLocationDropdown: false,
  cateringDialogue: false,
  guestCheckout: {},
  validateGuestCheckout: false,
});

export const mutations = {
  setGlobals: (state, obj) => {
    state.globals = obj
  },
  setShowMenu(state, bool) {
    state.showMenu = bool;
  },
  setHidingMenu(state, bool) {
    state.hidingMenu = bool;
  },
  setHidingCart(state, bool) {
    state.hidingCart = bool;
  },
  setHidingLocationDropdown(state, bool) {
    state.hidingLocationDropdown = bool;
  },
  setShowLocationDropdown(state, bool) {
    state.showLocationDropdown = bool;
  },
  setShowLocationDeliveryForm(state, bool) {
    state.showLocationDeliveryForm = bool
  },
  setCateringDialogue(state, bool) {
    state.cateringDialogue = bool;
  },
  setGuestCheckout(state, obj) {
    state.guestCheckout = obj;
  },
  setValidateGuestCheckout(state, bool) {
    state.validateGuestCheckout = bool;
  },
};

export const actions = {
  getGlobals: async ({ commit, state, rootState }, ctx) => {
    const globals = {}

    try {
      const popups = await ctx.$utils.content('_globals/popups')
      globals.popups = popups.content.sections[0]
    } catch (err) {
      console.error("Error fetching popups")
    }

    commit('setGlobals', globals)
  },
}


export const getters = {
  formattedLocations(state, getters, rootState) {
    const allLocations = rootState.locations.all.map((location) => {
      const stateName = us_states.find(
        (state) => state[0] === location.code
      )[1];

      const stateLocations = location.cities.reduce((acc, city) => {
        return [...acc, ...city.locations];
      }, []);

      return {
        name: stateName,
        locations: stateLocations,
      };
    });

    return allLocations;
  },
};
