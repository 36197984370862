
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  props: {
    upsells: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    // ...mapState({
    //   upsells: (state) => state.checkout.upsells,
    // }),
    ...mapState(["location", "cart"]),
    formattedUpsells() {
      return this.upsells.map((upsell) => {
        return {
          ...upsell,
          isInCart: this.cart.products.some(
            (product) => product.product_id === upsell.product_id
          ),
        };
      });
    },
  },
  mounted() {
    if (this.location) {
      this.$gtm.push({ ecommerce: null })
      this.$gtm.push({
        event: 'view_item_list',
        ecommerce: {
          location_code: this.location?.id,
          location_name: this.location?.name,
          items: this.formattedUpsells.map(item => ({
            item_id: item.id,
            item_name: item.name,
            item_type: "Upsell",
          })),
        },
      });
    }
  },
  methods: {
    ...mapMutations(["setLoading", "setErrors", "setMenu"]),
    ...mapMutations({
      setShowCart: "setShowCart",
      setLoading: "setLoading",
      setProduct: "product/setProduct",
      setCart: "setCart",
    }),
    ...mapActions({
      sendRequest: "sendRequest",
      quickAddProduct: "product/quickAddProduct",
      processMods: "product/processMods",
      selectMod: "product/selectMod",
      addProduct: "product/addProduct",
    }),
    async updateCart(config) {
      this.setLoading(true);
      const response = await this.sendRequest(config);
      if (response.status === "ok") {
        this.setCart(response.data);
        if (this.$route.name === "order-checkout") {
          let invalidCart = await this.validateCart();
          if (invalidCart) {
            this.setErrors([invalidCart]);
          } else {
            await this.getCart(this.cart.id);
          }
        }
      }
      this.setLoading(false);
    },
    async checkQuickAdd(product) {
      this.setProduct(null);
      this.setLoading(true);

      const { requiredModFound, productData } = await this.checkForRequiredMod(
        product
      );

      if (!requiredModFound) {
        // await this.quickAddProduct(product);

        this.setProduct(productData);
        await this.addProduct(productData);

        if (this.location) {
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: "add_to_cart",
            ecommerce: {
              value: product.cost,
              currency: "USD",
              location_name: this.location.name,
              location_id: this.location.id,
              items: [{
                item_id: product.id,
                item_name: product.name,
                item_type: "Upsell",
              }],
            }
          })
        }
      } else {
        if (product.name === "Pretzel Bites") {
          await this.preselectPretzelBiteDippingSauce(productData);
        }
      }

      this.setLoading(false);
    },
    async removeItem(product) {
      const productInCart = this.cart.products.find(
        (p) => p.name === product.name
      );
      await this.updateCart({
        method: "delete",
        url: `carts/${this.cart.id}/products/${productInCart.id}`,
      });
    },
    async checkForRequiredMod(product) {
      const config = {
        method: "get",
        url: `menus/${this.location.menu_id}/products/${product.id}`,
      };
      const response = await this.sendRequest(config);

      if (response.status === "ok") {
        const requiredModFound = response.data.mods.find((mod) => {
          if (mod.selects.mandatory) {
            return true;
          } else {
            return mod.options.find((option) => {
              return option.mods.find((subMod) => {
                if (subMod.selects.mandatory) {
                  return true;
                } else {
                  return subMod.options.find((subOption) => {
                    return subOption.mods.find((subSubMod) => {
                      if (subSubMod.selects.mandatory) {
                        return true;
                      } else {
                        return false;
                      }
                    });
                  });
                }
              });
            });
          }
        });

        return {
          requiredModFound,
          productData: response.data,
        };
      }

      return false;
    },
    async preselectPretzelBiteDippingSauce(productData) {
      this.setProduct(productData);
      await this.processMods();

      // preselect beer cheese
      const dippingSauceMod = productData.mods.find(
        (mod) => mod.name === "Choose Dipping Sauce"
      );
      const beerCheeseOption = dippingSauceMod.options.find(
        (option) => option.name === "Beer Cheese"
      );
      this.selectMod({
        ...beerCheeseOption,
        id: beerCheeseOption.choice_id,
        quantity: 1,
      });

      await this.addProduct();

      if (this.location) {
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: "add_to_cart",
          ecommerce: {
            value: productData.cost,
            currency: "USD",
            location_name: this.location.name,
            location_id: this.location.id,
            items: [{
              item_id: productData.id,
              item_name: productData.name,
              item_type: "Upsell",
            }],
          }
        })
      }
    },
  },
};
