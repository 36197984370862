import { render, staticRenderFns } from "./CurbsideForm.vue?vue&type=template&id=b262f7d2&scoped=true"
import script from "./CurbsideForm.vue?vue&type=script&lang=js"
export * from "./CurbsideForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b262f7d2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormField: require('/opt/build/repo/components/forms/FormField.vue').default,AppButton: require('/opt/build/repo/components/common/AppButton.vue').default})
