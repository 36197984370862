import Vue from 'vue'
Vue.mixin({
  filters: {
    phone(phone) {
      return phone
        .replace(/[^0-9]/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    },
    filename(str) {
      return str
        .split('\\')
        .pop()
        .split('/')
        .pop()
        .split('.')[0]
    }
  },
  methods: {
    calculateDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the Earth in kilometers
      const dLat = this.deg2rad(lat2 - lat1);
      const dLon = this.deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c; // Distance in kilometers
      const miles = this.convertToMiles(distance);
      const milesFormat = new Intl.NumberFormat('en-US', { style: 'decimal' }).format(miles);
      return milesFormat
    },
    convertToMiles(km) {
      // Conversion factor: 1 kilometer is approximately 0.621371 miles
      const conversionFactor = 0.621371;
    return (km * conversionFactor).toFixed(2);
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    resize(image, option) {
      const fileType = image.slice(-3)
      let endFile = ''
      const imageService = '//img2.storyblok.com/'
      const path = image.replace('//a.storyblok.com', '')
      const pathAb = path.replace('https:', '')

      endFile = fileType === 'svg' ? image : imageService + option + pathAb
      return endFile
    }

  }
})
