
export default {
  props: {
    color: {
      default: () => {
        return "#3D098E";
      },
    },
  },
};
