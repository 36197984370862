import { render, staticRenderFns } from "./HandoffModeSelector.vue?vue&type=template&id=13b91d37&scoped=true"
import script from "./HandoffModeSelector.vue?vue&type=script&lang=js"
export * from "./HandoffModeSelector.vue?vue&type=script&lang=js"
import style0 from "./HandoffModeSelector.vue?vue&type=style&index=0&id=13b91d37&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13b91d37",
  null
  
)

export default component.exports