
  export default {
    props: {
      color: {
        default: () => {
          return 'white'
        }
      },
      open: {
        default: () => {
          return true
        }
      }
    }
  }
