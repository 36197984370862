
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "LocSelector",
  props: {
    field: {
      type: Object,
      default: {
        name: "",
        label: "",
        type: "text",
        value: "",
        mask: "#####",
        placeholder: "Search Locations...",
        touched: false,
        error: null,
        errors: {
          invalid: "Please select a favorite location.",
        },
        locationSelect: {
          name: "location_select",
          label: "Select Location",
          show: true,
          required: true,
          value: "",
          error: null,
          errors: {
            invalid: "Please select a location",
          },
        },
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searched: false,
      providerLocations: null,
      selected: null,
      selectedLocation: "",
      isLoadingLocations: false,
    };
  },
  computed: {
    ...mapState({
      results: (state) => state.locations.results,
    }),
    ...mapGetters({
      formattedLocations: "app/formattedLocations",
    }),
    flattenedLocationsArray() {
      return this.formattedLocations.reduce(
        (acc, cur) => acc.concat(cur.locations),
        []
      );
    },
    locationsToShow() {
      if (this.field.value && this.results?.length) {
        return this.results;
      } else {
        return this.flattenedLocationsArray;
      }
    },
    sortedLocationsToShow() {
      return this.locationsToShow.sort((a, b) => {
        const stateComparison = a.state.localeCompare(b.state);
        if (stateComparison !== 0) return stateComparison;

        const nameA = a.c_storeShortName || a.name;
        const nameB = b.c_storeShortName || b.name;
        return nameA.localeCompare(nameB);
      });
    },
  },
  methods: {
    ...mapMutations(["setLoading", "setErrors"]),
    ...mapActions({
      addressSearch: "locations/addressSearch",
    }),
    ...mapActions(["sendRequest"]),
    async searchAddress(term = "") {
      this.setLoading(true);
      this.searched = false;

      let config = {
        method: "post",
        url: "members/options",
        headers: {
          verify: true,
        },
        data: {
          zip: term,
        },
      };
      try {
        let response = await this.sendRequest(config);
        if (response.status === "ok") {
          this.providerLocations = response.data.locations;
          await this.addressSearch(term);
        } else {
          this.setErrors(response.message);
        }
      } catch (error) {
        console.error(error);
      }

      this.searched = true;
      this.validate();
      this.setLoading(false);
    },
    validate() {
      let valid = this.field.value ? true : false;
      this.field.error = valid ? null : this.field.errors.invalid;
      return valid;
    },
    validateLocation() {
      let valid = this.field.locationSelect.value ? true : false;
      this.field.locationSelect.error = valid
        ? null
        : this.field.locationSelect.errors.invalid;
      return valid;
    },
    handleKeyup() {
      if (this.field.touched) {
        this.validate();
      }
    },
    handleBlur() {
      this.field.touched = true;
      this.validate();
    },
  },
  watch: {
    "field.value"(val) {
      if (val.length >= 5) {
        this.searchAddress(val);
      }
    },
  },
};
